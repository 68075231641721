import { useState } from "react";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "../../redux/global/global.actions";
import { useAppDispatch } from "../../redux/hooks";
import { UserProfileI } from "../../_interfaces";
import { RequestServices } from "../../_services";

const useProfile = (): [UserProfileI, () => void] => {
  const dispatch = useAppDispatch();
  const [userProfile, setUserProfile] = useState<UserProfileI>({
    attendanceAllowed: false,
    bankAccountNumber: "",
    bankCode: "",
    bankVerificationStatus: "",
    companyID: "",
    ewaAllowed: false,
    ewaCurrentLimit: 0,
    ewaMinimumRequestAmount: 0,
    ewaMonthlyLimit: 0,
    hierarchy: "",
    id: "",
    isBankSetup: false,
    isSupervisor: false,
    locationCode: "",
    locationName: "",
    name: "",
    nip: "",
    phoneNumber: "",
    pkID: 0,
    transactionCap: 0,
    userID: "",
    wageRequestCount: 0,
    ewaWalletBalance: 0,
    bankAccountName: ""
  });

  const getUserProfile = async () => {
    try {
      dispatch(showLoading());
      const fetchUser = await RequestServices.get();
      setUserProfile(fetchUser.data);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showAlert("Failed to get user profile", "error"));
      dispatch(hideLoading());
    }
  };

  return [userProfile, getUserProfile];
};

export default useProfile;
