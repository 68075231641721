/* eslint-disable react-hooks/exhaustive-deps */
import { hideAlert } from "redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { GrClose } from "react-icons/gr";
import { useEffect } from "react";

const Alert = () => {
  const dispatch = useAppDispatch();
  const { showAlert, alertMessage, alertType } = useAppSelector(
    (state) => state.globalState
  );

  const generateStyle = (type: string) => {
    switch (type) {
      case "success":
        return "bg-green-400";
      case "error":
        return "bg-red-400";
      default:
        return "bg-blue-400";
    }
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
    }
  }, [showAlert]);

  return (
    <div
      className={`border-0 transition-all ${generateStyle(
        alertType
      )} text-white rounded-lg px-5 py-2`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{
        opacity: showAlert ? 1 : 0,
        position: "fixed",
        margin: "10px",
        zIndex: showAlert ? 9999 : -1,
        right: showAlert ? 0 : "-1000px",
        top: 0,
      }}
    >
      <div className="flex justify-center items-center gap-2">
        <div className="toast-body">{alertMessage}</div>
        <button
          type="button"
          className="btn-close border-0 me-2 m-auto"
          style={{ background: "none", color: "#fff" }}
          aria-label="Close"
          onClick={() => dispatch(hideAlert())}
        >
          <GrClose fill="#fff" stroke="#fff" color="#fff" />
        </button>
      </div>
    </div>
  );
};

export default Alert;
