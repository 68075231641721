import Dashboard from "../layout/dashboard";
import DetailRegEmployee from "../pages/registered-employee/detail.page";
import RegisteredEmployee from "../pages/registered-employee/registered-employee.page";
import Home from "../pages/home/home.page";
import { Navigate } from "react-router-dom";

const useProtectedRoute = (): [any[]] => {
  const route = [
    {
      path: "/",
      element: <Dashboard />,
      name: "Dashboard",
      children: [
        {
          index: true,
          element: <Home />,
          name: "Home",
        },
        {
          path: "/karyawan",
          name: "Karyawan",
          children: [
            {
              path: "/karyawan/terdaftar",
              name: "Karyawan Terdaftar",
              children: [
                {
                  index: true,
                  element: <RegisteredEmployee />,
                },
                {
                  element: <DetailRegEmployee />,
                  path: "/karyawan/terdaftar/:id",
                },
              ],
            },
            {
              element: <>Karyawan Belum Terdaftar</>,
              path: "/karyawan/belum-terdaftar",
              name: "Karyawan Belum Terdaftar",
            },
          ],
        },
      ],
    },
    { path: "*", element: <Navigate to="/" replace /> }
  ];

  return [route];
};

export default useProtectedRoute;
