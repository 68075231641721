import { Login, Register } from "../_interfaces";
import request from "../_network/request";
const authUrl = "/auth";

const login = (payload: Login) => {
  return request({
    url: `${authUrl}/login`,
    method: "POST",
    data: payload,
  });
};

const register = (payload: Register) => {
  return request({
    url: `${authUrl}/signup`,
    method: "POST",
    data: payload,
  });
};

const exchangeToken = (token: string | null) => {
  return request({
    url: process.env.REACT_APP_BASE_URL_V2 + `${authUrl}/login/exchange-token`,
    method: "POST",
    data: { exchangeToken: token },
  });
};

export const AuthServices = {
  login,
  register,
  exchangeToken,
};
