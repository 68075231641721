import translateId from "../assets/translation/translate-id.json";
import translateEn from "../assets/translation/translate-en.json";
import { useAppSelector } from "../redux/hooks";

const useTranslate = (): [(text: string) => string] => {
  const { language } = useAppSelector((state) => state.globalState);
  const translate = (text: string) => {
    const trans: { [key: string]: any } =
      language === "en" ? translateEn : translateId;
    return trans[text];
  };

  return [translate];
};

export default useTranslate;
