const dateTimeFormatter = (date: string): string => {
  return new Date(date).toLocaleDateString("ID-id", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export default dateTimeFormatter;
