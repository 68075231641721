import { IoCloseSharp } from "react-icons/io5";

const ModalContainer = ({
  children,
  show = false,
  handleClose,
}: {
  children: JSX.Element;
  show: boolean;
  handleClose: () => void
}) => {
  return show ? (
    <div className="w-full h-screen flex justify-center items-center fixed inset-0 bg-black bg-opacity-30 z-10">
      <div className="container p-5">
        <div className="w-full flex flex-col gap-5 p-3 bg-white rounded-lg">
          <IoCloseSharp
            className="self-end text-2xl"
            onClick={handleClose}
          />
          {children}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ModalContainer;
