import { useEffect, useState } from "react";

const Button = ({
  type = "default",
  disabled = false,
  onClick = () => {},
  text,
  className = "",
  buttonType = "button"
}: {
  type: "default" | "danger" | "success" | "secondary";
  disabled?: boolean;
  onClick?: () => any;
  text: string;
  className?: string;
  buttonType?: "button" | "submit" | "reset"
}) => {
  const [style, setStyle] = useState(
    "bg-white hover:bg-slate-100 text-gray-600"
  );

  useEffect(() => {
    let tempStyle = "";
    switch (type) {
      case "danger":
        tempStyle = "bg-danger hover:bg-danger text-white";
        break;
      case "success":
        tempStyle = "bg-primary hover:bg-primary text-white";
        break;
      case "secondary":
        tempStyle = "bg-blue-400 hover:bg-blue-500 text-white";
        break;
      default:
        tempStyle = "bg-white hover:bg-slate-100 text-gray-700";
        break;
    }
    setStyle(tempStyle);
  }, [type]);

  return (
    <button
      className={`${style} ${className} transition-all border rounded-md w-fit px-7 py-1 disabled:bg-gray-200 disabled:cursor-not-allowed shadow-sm font-semibold`}
      {...onClick}
      disabled={disabled}
      type={buttonType}
    >
      {text}
    </button>
  );
};

export default Button;
