/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setHeader } from "../../redux/global/global.actions";
import { useAppDispatch } from "../../redux/hooks";

const DetailRegEmployee = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(
      setHeader({
        title: "Detail Registered Employee",
        hasBack: true,
      })
    );
  }, []);

  return <div>{params.id}</div>;
};

export default DetailRegEmployee;
