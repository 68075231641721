import AuthActionTypes from "./auth.types";

interface AuthState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  token: string | null;
  SSOToken: string | null;
  SSORefreshToken: string | null;
  failure: boolean;
}

const INITIAL_STATE: AuthState = {
  token: null,
  SSOToken: null,
  failure: false,
  SSORefreshToken: null,
};

const AuthReducer = (state = INITIAL_STATE, action: any): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        failure: false,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        failure: true,
      };
    case AuthActionTypes.EXCHANGE_TOKEN:
      return {
        ...state,
        failure: false,
      };
    case AuthActionTypes.EXCHANGE_TOKEN_SUCCESS:
      return {
        ...state,
        SSOToken: action.payload.authToken,
        SSORefreshToken: action.payload.refreshToken,
      };
    case AuthActionTypes.EXCHANGE_TOKEN_FAILURE:
      return {
        ...state,
        failure: true,
        SSOToken: null,
        SSORefreshToken: null,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        SSOToken: null,
        SSORefreshToken: null,
      };
    default:
      return state;
  }
};

export default AuthReducer;
