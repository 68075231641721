import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { IoIosArrowBack } from "react-icons/io";
import { FaHistory, FaHome } from "react-icons/fa";
import { RiAccountCircleFill } from "react-icons/ri";
import { logoutRedux } from "redux/auth/auth.actions";

const Dashboard = () => {
  const { hasBack } = useAppSelector((state) => state.globalState);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  return (
    <div className="w-full h-screen">
      <div className="container h-20 bg-white flex flex-row items-center gap-5 px-5 fixed top-0 shadow">
        {hasBack && (
          <div className="w-7 h-7 flex justify-center items-center">
            <IoIosArrowBack className=" text-2xl text-gray-700" />
          </div>
        )}
        <h1 className="font-semibold text-base text-gray-700">
          PT Djarum Hitam
        </h1>
      </div>
      <div className="py-20">
        <Outlet />
      </div>
      <div className="container bg-yellow-200 h-20 fixed bottom-0 shadow-lg border-t border-slate-100">
        <div className="h-20 grid grid-cols-3">
          <div className="h-full bg-white hover:bg-slate-100 transition-all duration-300 text-gray-700 flex flex-col justify-center items-center">
            <FaHome className="text-2xl" />
            <div className="font-semibold text-xs">Home</div>
          </div>
          <div className="h-full bg-white hover:bg-slate-100 transition-all duration-300 text-gray-700 flex flex-col justify-center items-center">
            <FaHistory className="text-2xl" />
            <div className="font-semibold text-xs">Transaction</div>
          </div>
          <div
            onClick={() => dispatch(logoutRedux())}
            className="h-full bg-white hover:bg-slate-100 transition-all duration-300 text-gray-700 flex flex-col justify-center items-center"
          >
            <RiAccountCircleFill className="text-2xl" />
            <div className="font-semibold text-xs">Profile</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
