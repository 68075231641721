import { WageHistoryReq } from "_interfaces";

const generateMonthYear = (date: string) => {
  // var date = "December 2018";
  let result: WageHistoryReq[] = [];

  const end_date = new Date(date.replace(" ", " ,1 "));
  const start_date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );

  while (end_date <= start_date) {
    result.push(
      {
        month: start_date.getMonth(),
        year: start_date.getFullYear(),
      }
      // start_date.toLocaleString("default", { month: "numeric", year: "numeric" })
    );
    start_date.setMonth(start_date.getMonth() - 1);
  }

  return result;
};

export default generateMonthYear;
