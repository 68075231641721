import {
  BankUpdate,
  WageCheck,
  WageHistoryReq,
  WageRequest,
} from "../_interfaces";
import request from "../_network/request";
const authUrl = "/employee/profile";

const get = () => {
  return request({
    url: `${authUrl}/current`,
    method: "GET",
  });
};

const updateBank = (payload: BankUpdate) => {
  return request({
    url: `user/update/bank-default`,
    method: "POST",
    data: payload,
  });
};

const wageRequest = (payload: WageRequest) => {
  return request({
    url: `ewa/wage-request/create`,
    method: "POST",
    data: payload,
  });
};

const wageCheck = (payload: WageCheck) => {
  return request({
    url: `ewa/wage-request/check`,
    method: "POST",
    data: payload,
  });
};

const wageRequestWallet = ({
  description,
  amount,
}: {
  description: string;
  amount: number;
}) => {
  return request({
    url: `ewa/wage-request/wallet`,
    method: "POST",
    data: {
      amount,
      description,
    },
  });
};

const wageHistory = (payload: WageHistoryReq) => {
  return request({
    url: `ewa/wallet/history?year=${payload.year}&month=${
      payload.month + 1
    }`,
    method: "GET",
  });
};

export const RequestServices = {
  get,
  updateBank,
  wageRequest,
  wageCheck,
  wageRequestWallet,
  wageHistory,
};
