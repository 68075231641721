import CurrencyInput from "components/masked-input-currency";
import { Field, Form, FormikErrors, FormikProps, withFormik } from "formik";
import { UserProfileI } from "_interfaces";

interface FormAmountValues {
  amount: string;
}

interface FormPropsI {
  onSubmit: (amount: number) => void;
  amount?: number;
}

const AmountInnerForm = (
  props: UserProfileI & FormikProps<FormAmountValues>
) => {
  const {
    touched,
    errors,
    wageRequestCount,
    transactionCap,
    ewaWalletBalance,
    ewaAllowed,
    bankVerificationStatus
  } = props;
  return (
    <Form className="w-full bg-white p-5 py-3 rounded-2xl shadow-md mt-5 border">
      <div className="flex flex-col gap-2 w-full box-border">
        <label className="text-gray-700 text-sm">Jumlah Penarikan</label>
        <Field
          name="amount"
          as={CurrencyInput}
          className={`bg-white disabled:bg-gray-100 rounded-md h-10 p-2 outline-none ${
            touched.amount && errors.amount
              ? "border-2 border-red-400"
              : "border border-gray-300"
          }`}
          disabled={
            (wageRequestCount >= transactionCap && transactionCap > 0) ||
            ewaWalletBalance <= 0 ||
            !ewaAllowed
          }
        />
        {touched.amount && errors.amount && (
          <div className="text-red-400 text-xs italic self-end">
            {errors.amount}
          </div>
        )}
        
        {
        !ewaAllowed ?
        <div className="text-yellow-400 text-xs italic self-end">
          Anda tidak memiliki akses penarikan, hubungi administrator
        </div>
        :
        bankVerificationStatus == 'PENDING' ? 
        <div className="text-yellow-400 text-xs italic self-end">
          Dalam proses validasi, tunggu beberapa menit untuk dapat melakukan penarikan
        </div>
        :
        bankVerificationStatus !== 'VALID' ? 
        <div className="text-red-400 text-xs italic self-end">
          Terdapat kesalahan pada bank: { bankVerificationStatus == "INVALID_ACCOUNT_NUMBER" ? "No. Rekening Tidak Valid" : bankVerificationStatus}
        </div>
        :
        null
        }
      </div>

      <button
        type="submit"
        className="w-full h-10 rounded-md bg-primary disabled:bg-[#898d9c] text-white mt-5"
        disabled={
          (wageRequestCount >= transactionCap && transactionCap > 0) ||
          ewaWalletBalance <= 0 ||
          !ewaAllowed || bankVerificationStatus !== "VALID"
        }
      >
        Penarikan
      </button>
    </Form>
  );
};

const AmountForm = withFormik<FormPropsI, FormAmountValues>({
  mapPropsToValues: (props) => ({
    amount: "",
  }),
  validate: (values: FormAmountValues) => {
    let errors: FormikErrors<FormAmountValues> = {};
    if (!values.amount) {
      errors.amount = "Required";
    }
    return errors;
  },
  handleSubmit: (values, { props }) => {
    const amount = Number(values.amount.replaceAll(".", "").replaceAll("Rp ", ""));
    props.onSubmit(amount);
  },
})(AmountInnerForm);

export default AmountForm;
