/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import {
  setHeader,
} from "../../redux/global/global.actions";
import { FaUserClock } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { BsFileEarmarkCheckFill } from "react-icons/bs";
import "./home.style.scss";
import Button from "../../components/button";
import { TbHandClick } from "react-icons/tb";
import { MdLocationOn } from "react-icons/md";
import { GiDuration } from "react-icons/gi";
import { ImEnter, ImExit } from "react-icons/im";
import useTranslate from "../../_helper/useTranslate";
// import useHome from "./useHome";

const Home = () => {
  const dispatch = useAppDispatch();
  const [translate] = useTranslate();
  // const [data, fetchData] = useHome();

  useEffect(() => {
    dispatch(setHeader({ title: "Home", hasBack: true }));
  }, []);

  return (
    <>
      <div className="w-full h-screen p-5 bg-white">
        <div className="w-full bg-blue-600 bg-opacity-80 p-5 py-3 rounded-2xl shadow-md">
          <h2 className="text-sm text-white font-semibold border-b pb-1 mb-2">
            {translate("home-greeting-morning")}, Dionisius!
          </h2>
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-col items-start">
              <h2 className="text-xs text-white">Limit Tersedia:</h2>
              <h1 className="text-lg font-bold text-blue-200">Rp 1.000.000</h1>
              <h2 className="text-[9px] text-red-100">Total limit:</h2>
              <h1 className="text-xs font-bold text-red-200">Rp 3.000.000</h1>
            </div>
            <Button
              type="default"
              onClick={() => {}}
              text="Tarik gaji"
              className=" max-h-10"
            />
          </div>
        </div>
        <div className="w-full bg-white p-5 py-3 rounded-2xl shadow-md mt-5 border">
          <h2 className="text-xl text-center text-gray-600 font-bold">
            Shift Pagi
          </h2>
          <p className="text-xs text-center text-gray-400">08:00 - 17:00</p>
          <div className="w-full flex justify-center items-center mt-5">
            <button className="w-32 h-32 rounded-full from-indigo-500 to-indigo-700 bg-gradient-to-tr hover:from-indigo-400 hover:to-indigo-700 shadow-xl flex flex-col justify-center items-center gap-0.5">
              <TbHandClick className="w-16 h-16 text-indigo-100" />
              <p className="text-xs text-indigo-50 font-bold">Keluar</p>
            </button>
          </div>
          <div className="w-full flex flex-row items-center justify-center mt-3">
            <MdLocationOn className="text-gray-500" />
            <p className="text text-xs text-gray-500 tracking-tighter">
              Location: Bandung Office
            </p>
          </div>
          <div className="w-full grid grid-cols-3 mt-5">
            <div className="flex flex-col items-center justify-center">
              <ImEnter className="text-indigo-500 w-5 h-5" />
              <div className="text-base font-bold text-gray-600">08:00</div>
              <div className="text-xs text-gray-400">Masuk</div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <ImExit className="text-indigo-500 w-5 h-5" />
              <div className="text-base font-bold text-gray-600">--:--</div>
              <div className="text-xs text-gray-400">Keluar</div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <GiDuration className="text-indigo-500 w-5 h-5" />
              <div className="text-base font-bold text-gray-600">04:35</div>
              <div className="text-xs text-gray-400">Durasi</div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <h1 className="text-lg text-gray-700 font-bold">Other Feature</h1>
          <div className="w-full py-5 flex flex-col gap-5">
            <div className="w-full flex flex-row justify-between items-center gap-5 p-3 rounded-2xl bg-slate-100">
              <div className="flex justify-center items-center w-14 h-14 bg-orange-300 rounded-2xl">
                <FaUserClock className="text-3xl text-white" />
              </div>
              <div className="flex flex-col flex-grow">
                <h1 className="text-gray-600">Attendance</h1>
                <h2 className="text-gray-400 text-xs">
                  Absen masuk di jam Anda.
                </h2>
              </div>
              <IoIosArrowForward />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-5 p-3 rounded-2xl bg-slate-100">
              <div className="flex justify-center items-center w-14 h-14 bg-red-400 rounded-2xl">
                <BsFileEarmarkCheckFill className="text-3xl text-white" />
              </div>
              <div className="flex flex-col flex-grow">
                <h1 className="text-gray-600">Koperasi Digital</h1>
                <h2 className="text-gray-400 text-xs">
                  Lacak dan catat KPI Anda.
                </h2>
              </div>
              <IoIosArrowForward />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
