import AuthActionTypes from "./auth.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";
import { AuthServices } from "../../_services";

export const loginRedux = (username: string, password: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const login = await AuthServices.login({ username, password });
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: login.data.authToken,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: "Login Success",
            alertType: "success",
          },
        });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: "error",
          },
        });
      });
    }
  };
};

export const logoutRedux = () => {
  return async (dispatch: any) => {
    dispatch({ type: AuthActionTypes.LOGOUT });
  };
};

export const exchangeToken = (token: string | null) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: AuthActionTypes.EXCHANGE_TOKEN });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const res = await AuthServices.exchangeToken(token);

      batch(() => {
        dispatch({
          type: AuthActionTypes.EXCHANGE_TOKEN_SUCCESS,
          payload: res.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: AuthActionTypes.EXCHANGE_TOKEN_FAILURE });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertMessage: error,
            alertType: "error",
          },
        });
      });
    }
  };
};
