import Button from "components/button";
import { Field, Form, FormikErrors, FormikProps, withFormik } from "formik";
import RupiahFormatter from "_helper/rupiah-formatter";
import { UserProfileI, WageFee } from "_interfaces";

interface FormDescriptionValues {
  amount: number;
  description: string;
}

interface FormPropsI {
  onSubmit: (payload: FormDescriptionValues) => void;
  amount: number;
  description: string;
}

const DescriptionInnerForm = (
  props: UserProfileI & WageFee & FormikProps<FormDescriptionValues>
) => {
  const {
    // touched,
    // errors,
    // isSubmitting,
    handleSubmit,
    fee,
    amount,
    bankAccountName,
    bankAccountNumber,
    bankCode,
  } = props;
  return (
    <Form
      className="flex flex-col gap-5"
      onReset={() => {}}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col items-center p-3 bg-white border-2 border-blue-300 rounded-xl">
        <div className="w-full flex flex-row justify-between items-center">
          <div className="font-normal text-xs text-gray-600">
            Nominal Yang Ditarik
          </div>
          <div className="font-semibold text-base text-gray-600">
            {RupiahFormatter(amount)}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="font-normal text-xs text-gray-600">
            Biaya Penarikan
          </div>
          <div className="font-semibold text-base text-gray-600">
            {RupiahFormatter(fee)}
          </div>
        </div>
        <div className="w-full border border-blue-300 mt-3" />
        <div className="w-full flex flex-row justify-between items-center">
          <div className="font-semibold text-xs text-gray-600">
            Nominal Yang Anda Dapat
          </div>
          <div className="font-bold text-lg text-gray-600">
            {RupiahFormatter(amount - fee)}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="text-xs font-semibold">
          Nominal akan di transfer ke*:
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="font-normal text-sm text-gray-600">Bank</div>
          <div className="font-semibold text-sm text-gray-600">{bankCode}</div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="font-normal text-sm text-gray-600">
            Nomor Rekening
          </div>
          <div className="font-semibold text-sm text-gray-600">
            {bankAccountNumber}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="font-normal text-sm text-gray-600">Nama Rekening</div>
          <div className="font-semibold text-sm text-gray-600">
            {bankAccountName}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full box-border">
        <label className="text-gray-700 text-sm">Alasan Penarikan</label>
        <Field
          placeholder="Alasan penarikan ...."
          name="description"
          as={TextAreaComponent}
        />
      </div>
      <div className="flex flex-row justify-between items-center">
        <Button
          type="danger"
          text="Batal"
          buttonType="reset"
          className="max-h-10"
        />

        <Button
          type="success"
          text="Konfirmasi"
          className=" max-h-10"
          buttonType="submit"
        />
      </div>
    </Form>
  );
};

const DescriptionForm = withFormik<FormPropsI, FormDescriptionValues>({
  mapPropsToValues: (props) => ({
    description: "",
    amount: props.amount,
  }),
  validate: (values: FormDescriptionValues) => {
    let errors: FormikErrors<FormDescriptionValues> = {};
    if (!values.description) {
      errors.description = "Required";
    }
    return errors;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(DescriptionInnerForm);

const TextAreaComponent = (props: any) => (
  <textarea
    className="bg-white rounded-md h-20 p-2 border"
    {...props}
    required
  />
);

export default DescriptionForm;
