import { useState } from "react";
import {
  hideLoading,
  showAlert,
  showLoading,
} from "redux/global/global.actions";
import { useAppDispatch } from "redux/hooks";
import {
  WageFee,
  WageHistoryReq,
  WageHistoryRes,
  WageRequest,
} from "_interfaces";
import { RequestServices } from "_services";

const useEwaRequest = (): [
  "requesting" | "success" | "failure",
  (payload: WageRequest) => void,
  WageHistoryRes[],
  (payload: WageHistoryReq) => void,
  WageFee,
  (amount: number) => void
] => {
  const [status, setStatus] = useState<"requesting" | "success" | "failure">(
    "requesting"
  );
  const [history, setHistory] = useState<Array<WageHistoryRes>>([]);
  const [wageFee, setWageFee] = useState<WageFee>({
    amount: 0,
    fee: 0,
  });
  const dispatch = useAppDispatch();

  const doRequest = async (payload: WageRequest) => {
    try {
      setStatus("requesting");
      dispatch(showLoading());
      await RequestServices.wageRequestWallet({
        description: payload.description,
        amount: payload.amount,
      });
      setStatus("success");
      dispatch(hideLoading());
    } catch (error) {
      setStatus("failure");
      dispatch(hideLoading());
      dispatch(showAlert("Wage Request Failed", "error"));
    }
  };

  const getHistory = async (payload: WageHistoryReq) => {
    try {
      dispatch(showLoading());
      const res = await RequestServices.wageHistory(payload);
      setHistory(res.data.histories);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const getWageFee = async (amount: number) => {
    try {
      dispatch(showLoading());
      const res = await RequestServices.wageCheck({ amount });
      setWageFee(res.data);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(showAlert(error, "error"));
      dispatch(hideLoading());
    }
  };

  return [status, doRequest, history, getHistory, wageFee, getWageFee];
};

export default useEwaRequest;
