const Badge = ({
  text,
  backgroundColor,
  textColor,
}: {
  text: string,
  backgroundColor: string,
  textColor: string,
}) => {
  return (
    <div className={`rounded-lg text-center py-1 w-28 ${backgroundColor} ${textColor} text-xs h-fit`}>
      {text}
    </div>
  );
};

export default Badge;
