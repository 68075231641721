const Chips = ({
  text,
  backgroundColor,
  textColor,
}: {
  text: string,
  backgroundColor: string,
  textColor: string,
}) => {
  return (
    <div className={`rounded-xl text-center py-1 w-20 ${backgroundColor} ${textColor} text-[8px] h-fit`}>
      {text}
    </div>
  );
};

export default Chips;
