const AuthActionTypes = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  EXCHANGE_TOKEN: "EXCHANGE_TOKEN",
  EXCHANGE_TOKEN_SUCCESS: "EXCHANGE_TOKEN_SUCCESS",
  EXCHANGE_TOKEN_FAILURE: "EXCHANGE_TOKEN_FAILURE",
  SIGN_UP: "SIGN_UP",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  LOGOUT: "LOGOUT",
};

export default AuthActionTypes;
