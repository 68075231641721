/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import Logo from "assets/images/logo.png";
import {
  IoArrowForwardCircleSharp,
  IoArrowBackCircleSharp,
} from "react-icons/io5";
import useProfile from "hooks/client/useProfile";
import RupiahFormatter from "_helper/rupiah-formatter";
import Chips from "components/chips";
import ModalContainer from "components/modal-container";
import useEwaRequest from "hooks/client/useEwaRequest";
import { WageHistoryReq, WageRequest } from "_interfaces";
import generateMonthYear from "_helper/generate-month-year";
import generateMonthName from "_helper/generate-month-name";
import AmountForm from "./amount-form";
import DescriptionForm from "./description-form";
import Badge from "components/badge";
import dateTimeFormatter from "_helper/date-time-formatter";

const Client = () => {
  const [showModal, setShowModal] = useState(false);
  const { SSOToken } = useAppSelector((state) => state.auth);
  const [userProfile, getUserProfile] = useProfile();
  const [formData, setFormData] = useState<WageRequest>({
    amount: 0,
    description: "",
    fee: 0,
  });
  const [status, ewaRequest, history, getHistory, wageFee, getWageFee] =
    useEwaRequest();
  const [indexOfList, setIndexOfList] = useState(0);
  const listOfMonths = generateMonthYear("January 2019");

  useEffect(() => {
    if (SSOToken) getUserProfile();
  }, [SSOToken]);

  useEffect(() => {
    if (status === "success") {
      setFormData({ amount: 0, description: "", fee: 0 });
      setShowModal(false);
      getUserProfile();
      setIndexOfList(0);
      fetchHistory(listOfMonths[0]);
    }
  }, [status]);

  useEffect(() => {
    fetchHistory(listOfMonths[indexOfList]);
  }, [indexOfList]);

  useEffect(() => {
    if (wageFee.amount !== 0) {
      setFormData((item) => ({
        ...item,
        amount: wageFee.amount,
        fee: wageFee.fee,
      }));
      setShowModal(true);
    }
  }, [wageFee]);

  const fetchHistory = (monthYear: WageHistoryReq) => {
    getHistory(monthYear);
  };

  const generateColor = (status: string): string => {
    switch (status) {
      case "PROCESSING":
        return "bg-blue-400";
      case "SUCCESS":
        return "bg-primary";
      case "ACCEPTED":
        return "bg-orange-400";
      case "DISBURSING":
        return "bg-indigo-400";
      default:
        return "bg-red-400";
    }
  };

  const generateTextFromType = (type: string): string => {
    switch (type) {
      case "wallet":
        return "Pemasukan";
      default:
        return "Penarikan";
    }
  };

  const generateTypeColor = (type: string): string => {
    switch (type) {
      case "wallet":
        return "bg-primary";
      default:
        return "bg-danger";
    }
  };

  return (
    <>
      <div className="w-full min-h-screen">
        <div className="w-full p-5">
          <div className="flex flex-col justify-center items-center gap-2 mb-5">
            <div className="flex justify-center items-center">
              <img src={Logo} alt="gajiku" className="w-auto h-24" />
            </div>
            <h1 className="font-bold text-2xl text-gray-700">
              Tarik Gaji by Gajiku
            </h1>
          </div>
          <div className="pt-5">
            <div className="flex flex-row justify-between items-center p-5 bg-primary rounded-2xl">
              <div className="font-semibold text-base text-white">Saldo</div>
              <div className="font-bold text-xl text-white">
                {RupiahFormatter(userProfile.ewaWalletBalance)}
              </div>
            </div>
            <AmountForm
              {...userProfile}
              onSubmit={(amount) => {
                getWageFee(amount);
              }}
              amount={formData.amount}
            />
          </div>
        </div>
        <div className="w-full bg-white pt-5">
          <div className="w-100 flex flex-row justify-around items-center">
            <IoArrowBackCircleSharp
              className={`text-2xl ${
                indexOfList === listOfMonths.length - 1
                  ? "text-gray-300"
                  : "text-gray-700"
              }`}
              onClick={() => {
                if (indexOfList !== listOfMonths.length - 1) {
                  setIndexOfList((temp) => temp + 1);
                }
              }}
            />
            <div className="text-base font-semibold">
              {generateMonthName(listOfMonths[indexOfList].month)}{" "}
              {listOfMonths[indexOfList].year}
            </div>
            <IoArrowForwardCircleSharp
              className={`text-2xl ${
                indexOfList === 0 ? "text-gray-300" : "text-gray-700"
              }`}
              onClick={() => {
                if (indexOfList !== 0) {
                  setIndexOfList((temp) => temp - 1);
                }
              }}
            />
          </div>
          <div className="p-5 min-h-[430px]">
            {history.length > 0 ? (
              history.map((item) => (
                <div
                  key={item.id}
                  className="w-full bg-white p-5 py-3 rounded-2xl shadow-md mt-3 border"
                >
                  <div className="flex flex-row items-center justify-between">
                    <Chips
                      text={generateTextFromType(item.type)}
                      textColor="text-white"
                      backgroundColor={generateTypeColor(item.type)}
                    />
                    <div className="text-[9px]">
                      {dateTimeFormatter(item.createdAt)}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-lg text-gray-700 font-semibold">
                      {RupiahFormatter(item.amount)}
                    </div>
                    <Badge
                      text={item.status}
                      textColor="text-white"
                      backgroundColor={generateColor(item.status)}
                    />
                  </div>
                  <div className="text-xs mt-1 text-gray-500">
                    {item.description}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 text-sm">
                No History Data
              </div>
            )}
          </div>
        </div>
      </div>
      {/* modal */}
      {showModal && (
        <ModalContainer
          show={showModal}
          handleClose={() => setShowModal(false)}
        >
          <DescriptionForm
            {...formData}
            {...userProfile}
            onSubmit={(payload) => {
              ewaRequest({...payload, fee: 0});
            }}
          />
        </ModalContainer>
      )}
    </>
  );
};

export default Client;
