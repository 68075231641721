/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setHeader } from "../../redux/global/global.actions";

const RegisteredEmployee = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHeader({ title: "Registered Employee" }));
  }, []);

  return (
    <>
      {/* <Filter headers={tableHeaders} />
      <DataTable
        headers={tableHeaders}
        listData={listData}
        first={true}
        last={true}
        totalPage={10}
        empty={false}
        page={0}
        handleAction={() => {}}
        size={5}
        listAction={actionButton}
      /> */}
    </>
  );
};

export default RegisteredEmployee;
