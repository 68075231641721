const GlobalActionTypes = {
  SET_HEADER: "SET_HEADER",
  SHOW_LOADING: "SHOW_LOADING",
  HIDE_LOADING: "HIDE_LOADING",
  SHOW_SUCCESS: "SHOW_SUCCESS",
  HIDE_SUCCESS: "HIDE_SUCCESS",
  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
  LANGUAGE: "LANGUAGE",
};

export default GlobalActionTypes;
