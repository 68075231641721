import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { loginRedux } from 'redux/auth/auth.actions';
import Logo from 'assets/images/logo.png';

const Login = () => {
  const [form, setForm] = useState({
    username: '',
    password: '',
  });
  const dispatch = useAppDispatch();

  const login = (e: any) => {
    e.preventDefault();
    dispatch(loginRedux(form.username, form.password));
  };

  const handleChange = (e: any) => {
    setForm((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <img src={Logo} alt="gajiku" className="mb-10 h-24" />
      <form className="flex flex-col gap-4" onSubmit={login}>
        <div className="flex flex-col w-80 gap-2">
          <label className="text-gray-700 text-sm">Username</label>
          <input
            type="text"
            placeholder="Username"
            name="username"
            className="bg-white rounded-md h-10 p-2"
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-col w-80 gap-2">
          <label className="text-gray-700 text-sm">Password</label>
          <input
            type="password"
            placeholder="Password"
            name="password"
            className="bg-white rounded-md h-10 p-2"
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="w-full h-10 rounded-md bg-primary text-white mt-10">
          Log In
        </button>
      </form>
      {/* <iframe src="http://localhost:3000/asdfasdf" title="gajiku" className="h-screen w-full" /> */}
    </div>
  );
};

export default Login;
