/* eslint-disable react-hooks/exhaustive-deps */
import {
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import Client from "layout/client";
import Login from "layout/login";
import Tnc from "layout/tnc";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import useProtectedRoute from "./routes";
import { VscLoading } from "react-icons/vsc";
import { useEffect } from "react";
import Alert from "components/alert";
import { exchangeToken } from "redux/auth/auth.actions";

const MainRoutes = () => {
  const { loading } = useAppSelector((state) => state.globalState);
  const { token, SSOToken } = useAppSelector((state) => state.auth);
  const [protectedRoute] = useProtectedRoute();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const publicUrls: string[] = [
    '/terms-and-conditions',
  ];
  let routes: RouteObject[] = [
    {
      path: "/login",
      element: <Login />,
      index: true,
    },
    {
      path: "/client",
      element: <Client />,
    },
    {
      path: "/terms-and-conditions",
      element: <Tnc />,
    },
    { path: "*", element: <Navigate to="/login" replace /> },
  ];

  let element = useRoutes(token ? protectedRoute : routes);
  useEffect(() => {
    if (!searchParams.get("t")) {
      if (publicUrls.indexOf(location.pathname) === -1) {
        navigate("/login");
      }
    } else {
      dispatch(exchangeToken(searchParams.get("t")));
    }
  }, [token, searchParams]);

  useEffect(() => {
    if (publicUrls.indexOf(location.pathname) === -1) {
      if (SSOToken) navigate("/client");
      if (location.pathname === "/client" && !SSOToken) navigate("login");
    }
  }, [SSOToken]);

  return (
    <div>
      {loading && (
        <div className="fixed container h-screen flex items-center justify-center bg-black bg-opacity-50 z-50">
          <VscLoading className="text-3xl animate-spin" />
        </div>
      )}
      <Alert />
      {element}
    </div>
  );
};

export default MainRoutes;
